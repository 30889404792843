export default {
  'Calc': () => import('./Calc/Calc'),
  'CalcProiz': () => import('./Calc/CalcProiz/CalcProiz'),
  'CalcHouse': () => import('./CalcHouse/CalcHouse'),
  'Galery': () => import('./Gallery/Galery'),
  'Gallery': () => import('./Gallery/Gallery'),
  'QuizProject': () => import('./QuizProject/QuizProject'),
  'CalcDetails': () => import('./CalcDetails/CalcDetails'),

  'ConsultationTwo': () => import('./Form/ConsultationTwo'),
  'Consultation': () => import('./Form/Consultation'),
  'FormTwo': () => import('./Form/FormTwo'),
  'IpotekaModern': () => import('./Form/Ipoteka/Calc'),

  'NavBottom': () => import('./NavBar/NavBottom'),
  'VideoDesctop': () => import('./Video/VideoDesctop'),

  'Youtube': () => import('./Youtube/video'),
  'PortfolioList': () => import('./Portfolio/List'),
}
